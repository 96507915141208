.carousel .scrollButtonIcon {
  visibility: hidden;
}

.carousel:hover .scrollButtonIcon {
  visibility: visible;
}

.container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.container::-webkit-scrollbar {
  display: none;
}

.carousel:hover .scrollButtonWhiteLeft {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.8) 11.47%,
    rgba(255, 255, 255, 0.5) 100%
  );
}

.carousel:hover .scrollButtonWhiteRight {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.8) 11.47%,
    rgba(255, 255, 255, 0.5) 100%
  );
}

.carousel:hover .scrollButtonGrayLeft {
  background: linear-gradient(
    90deg,
    rgba(245, 246, 247, 0.8) 11.47%,
    rgba(245, 246, 247, 0.5) 100%
  );
}

.carousel:hover .scrollButtonGrayRight {
  background: linear-gradient(
    270deg,
    rgba(245, 246, 247, 0.8) 11.47%,
    rgba(245, 246, 247, 0.5) 100%
  );
}

.scrollButton:hover svg {
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.06));
}

.scrollButtonIcon {
}
.scrollButtonWhiteLeft {
}
.scrollButtonWhiteRight {
}
.scrollButtonGrayLeft {
}
.scrollButtonGrayRight {
}
