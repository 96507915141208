.bottomDownloadSectionBackground {
  background-image: url('./assets/BDSectionWaveMobile.svg');
}

@screen sm {
  .bottomDownloadSectionBackground {
    background-image: url('./assets//BDSectionWaveDesktop.svg');
  }
}

@screen lg {
  .bottomDownloadSectionBackground {
    background-image: url('./assets//BDSectionWaveXL.svg');
  }
}